import { Col, Row } from "react-bootstrap";
import DateFormatter from "../../../helpers/date-formatter";
import { Station } from "../../../models/station";
import LxCard from "../../layout/LxCard";
import PollButton from "../PollButton";
import StationCountersTable from "./StationCountersTable";
import StationOffTimesTable from "./StationOffTimesTable";
import StationOnTimesTable from "./StationOnTimesTable";
import StationStatusTable from "./StationStatusTable";

interface Props {
  station: Station;
}

const StationDetailContent = ({ station }: Props) => {
  const counters = station.DigitalInputs.filter(
    (digitalInput) => digitalInput.Config.Enable && digitalInput.Config.Counter
  );

  const onTimes = station.DigitalInputs.filter(
    (digitalInput) => digitalInput.Config.Enable && digitalInput.Config.OnTime
  );

  // onTimes.forEach((element) => {
  //   element.State.OnTimeTotal /= 10.0;
  //   element.State.OnTimeToday /= 10.0;
  //   element.State.OnTimeYday /= 10.0;
  // });

  const offTimes = station.DigitalInputs.filter(
    (digitalInput) => digitalInput.Config.Enable && digitalInput.Config.OffTime
  );

  // offTimes.forEach((element) => {
  //   element.State.OnTimeTotal /= 10.0;
  //   element.State.OnTimeToday /= 10.0;
  //   element.State.OnTimeYday /= 10.0;
  // });

  const lastUpdateFormatted = DateFormatter.format(
    new Date(station.State.LastUpdate)
  );

  return (
    <>
      <p>{`Overview for ${station.Config.SiteName} - ${station.Config.Address}`}</p>
      <Row>
        <Col xl={8}>
          <LxCard title="Status">
            <StationStatusTable
              digitalInputs={station.DigitalInputs.filter(
                (digitalInput) => digitalInput.Config.Enable
              )}
            />
          </LxCard>
        </Col>
        <Col xl={4}>
          {station.State.CommFailureState && (
            <Row>
              <Col>
                <LxCard title="Comm Alarm" type="danger" text="white">
                  Station is in communication alarm.
                </LxCard>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <LxCard title="Last Update">{lastUpdateFormatted}</LxCard>
            </Col>
          </Row>
          <Row>
            <Col>
              <LxCard title="Poll Station">
                <PollButton id={station.StationId} />
              </LxCard>
            </Col>
          </Row>
        </Col>
      </Row>
      {counters.length > 0 && (
        <Row>
          <Col xl={8}>
            <LxCard title="Counters">
              <StationCountersTable digitalInputs={counters} />
            </LxCard>
          </Col>
        </Row>
      )}
      {onTimes.length > 0 && (
        <Row>
          <Col xl={8}>
            <LxCard title="On Times">
              <StationOnTimesTable digitalInputs={onTimes} />
            </LxCard>
          </Col>
        </Row>
      )}
      {offTimes.length > 0 && (
        <Row>
          <Col xl={8}>
            <LxCard title="Off Times">
              <StationOffTimesTable digitalInputs={offTimes} />
            </LxCard>
          </Col>
        </Row>
      )}
    </>
  );
};

export default StationDetailContent;
