import "@progress/kendo-theme-bootstrap/dist/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import "bootswatch/dist/darkly/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.min.css";
import { Container } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import MainHeader from "./components/layout/MainHeader";
import "./components/layout/styles.css";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Map from "./pages/Map";
import Reports from "./pages/Reports";
import Station from "./pages/Station";
import Stations from "./pages/Stations";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSyncAlt,
  faHome,
  faUser,
  faChartBar,
  faMapMarkedAlt,
  faEdit,
  faTrashAlt,
  faAngleUp,
  faAngleDown,
  faPlus,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import AuthenticatedRoute from "./components/layout/AuthenticatedRoute";
import { ToastContainer } from "react-toastify";
import NotFound from "./pages/NotFound";
import ReportsCount from "./pages/ReportsCount";
import ReportsOnTime from "./pages/ReportsOnTime";
import ReportsOffTime from "./pages/ReportsOffTime";
import MainFooter from "./components/layout/MainFooter";
import Account from "./pages/Account";
import AdminRoute from "./components/layout/AdminRoute";
import Users from "./pages/Users";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import AlarmLog from "./pages/AlarmLog";
import Grid from "./pages/Grid";
import SignalRContext from "./contexts/SignalRContext";
import AppSettings from "./helpers/app-settings";
import Manage from "./pages/Manage";
import ManageStation from "./components/manage/ManageStation";
import Alerts from "./pages/Alerts";

// All font awsome icons have to be pre-loaded to be available for use
library.add(
  faSyncAlt,
  faHome,
  faUser,
  faChartBar,
  faMapMarkedAlt,
  faEdit,
  faTrashAlt,
  faAngleUp,
  faAngleDown,
  faPlus,
  faGear
);

const queryClient = new QueryClient();

// Toast Container holds the error messages
// Switch matches only one route
// Authenticated routes check for user login first
// Regular route is only allowed to the login page
const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer position="bottom-right" hideProgressBar />
      <MainHeader />
      <Routes>
        <Route
          path="/"
          element={
            <AuthenticatedRoute>
              <Container as="main">
                <Home />
              </Container>
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/stations"
          element={
            <AuthenticatedRoute>
              <Container as="main">
                <Stations />
              </Container>
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/grid"
          element={
            <AuthenticatedRoute>
              <Container as="main" fluid>
                <SignalRContext.Provider
                  connectEnabled={true}
                  url={`${AppSettings.ApiBaseUrl}/stationhub`}
                >
                  <Grid />
                </SignalRContext.Provider>
              </Container>
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/stations/:id"
          element={
            <AuthenticatedRoute>
              <Container as="main">
                <Station />
              </Container>
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/reports"
          element={
            <AuthenticatedRoute>
              <Container as="main">
                <Reports />
              </Container>
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/reports/count/:id"
          element={
            <AuthenticatedRoute>
              <Container as="main">
                <ReportsCount />
              </Container>
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/reports/ontime/:id"
          element={
            <AuthenticatedRoute>
              <Container as="main">
                <ReportsOnTime />
              </Container>
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/reports/offtime/:id"
          element={
            <AuthenticatedRoute>
              <Container as="main">
                <ReportsOffTime />
              </Container>
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/map"
          element={
            <AuthenticatedRoute>
              <Container as="main">
                <SignalRContext.Provider
                  connectEnabled={true}
                  url={`${AppSettings.ApiBaseUrl}/stationhub`}
                >
                  <Map />
                </SignalRContext.Provider>
              </Container>
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/account"
          element={
            <AuthenticatedRoute>
              <Container as="main">
                <Account />
              </Container>
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/alarms"
          element={
            <AuthenticatedRoute>
              <Container as="main">
                <AlarmLog />
              </Container>
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/config/users"
          element={
            <AdminRoute>
              <Container as="main">
                <Users />
              </Container>
            </AdminRoute>
          }
        />
        <Route
          path="/config/stations"
          element={
            <AdminRoute>
              <Container as="main">
                <Manage />
              </Container>
            </AdminRoute>
          }
        />
        <Route
          path="/config/stations/:id"
          element={
            <AdminRoute>
              <Container as="main" fluid>
                <ManageStation />
              </Container>
            </AdminRoute>
          }
        />
        <Route
          path="/config/alerts"
          element={
            <AdminRoute>
              <Container as="main">
                <Alerts />
              </Container>
            </AdminRoute>
          }
        />
        <Route
          path="/login"
          element={
            <Container as="main">
              <Login />
            </Container>
          }
        />
        <Route
          element={
            <Container as="main">
              <NotFound />
            </Container>
          }
        />
      </Routes>
      <MainFooter />
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
};

export default App;
