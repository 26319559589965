import { useEffect, useState } from "react";
import AutoBreadCrumb from "../components/layout/AutoBreadCrumb";
import LxCard from "../components/layout/LxCard";
import agent from "../api/agent";
import { Button, Spinner, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AccountStationCreateRequest,
  AccountStationResponse,
  AccountStationUpdateRequest,
} from "../models/user";
import NiceModal from "@ebay/nice-modal-react";
import UserAlertUpdateForm from "../components/users/UserAlertUpdateForm";
import { toast } from "react-toastify";
import AlertCreateForm from "../components/forms/AlertCreateForm";

const Alerts = () => {
  useEffect(() => {
    document.title = "LxPro - Alert Settings";
  }, []);

  const [alertSettings, setAlertSettings] = useState<
    AccountStationResponse[] | undefined
  >(undefined);

  useEffect(() => {
    const fetchAlertSettings = async () => {
      const loadedSettings = await agent.AccountStationsAdmin.list();
      setAlertSettings(loadedSettings);
    };

    fetchAlertSettings();
  }, []);

  const fetchAlertSettings = async () => {
    const loadedSettings = await agent.AccountStationsAdmin.list();
    setAlertSettings(loadedSettings);
  };

  const onUpdateAlert = async (alert: AccountStationUpdateRequest) => {
    try {
      await agent.AccountStations.update([alert]);
      toast.success(`Successfully edited AlertSetting ${alert.Id}.`);

      await fetchAlertSettings();
    } catch (error) {
      toast.error("Failed to edit alert setting.");
    }

    NiceModal.hide("edit-alert");
  };

  const updateFormHandler = (alert: AccountStationResponse) => {
    if (alert) {
      NiceModal.show("edit-alert", {
        title: "Edit Station Alert",
        size: "sm",
        children: (
          <UserAlertUpdateForm
            alert={alert}
            updateAlertSetting={onUpdateAlert}
          />
        ),
      });
    }
  };

  const alertSettingDeleteHandler = async (id: number) => {
    try {
      await agent.AccountStations.delete(id.toString());
      toast.success(`Successfully deleted AlertSetting ${id}`);

      await fetchAlertSettings();
    } catch (error) {
      toast.error(`Failed to delete AlertSetting ${id}`);
    }
  };

  const onAddAlert = async (alert: AccountStationCreateRequest) => {
    try {
      await agent.AccountStationsAdmin.create([alert]);
      toast.success(`Added alert setting for Station ${alert.StationId}.`);

      await fetchAlertSettings();
    } catch (error) {
      toast.error("Failed to add alert setting.");
    }

    NiceModal.hide("create-alert");
  };

  const showCreateForm = () => {
    NiceModal.show("create-alert", {
      title: "Add Station Alert",
      size: "sm",
      children: <AlertCreateForm addAlertSetting={onAddAlert} />,
    });
  };

  return (
    <>
      <AutoBreadCrumb
        paths={[
          { name: "Home", link: "/", active: false },
          { name: "Config", link: "/", active: true },
          { name: "Alerts", link: "/config/alerts", active: true },
        ]}
      />
      <h1>Alerts</h1>
      <LxCard title="Alerts">
        {alertSettings && (
          <Table responsive striped hover>
            <thead>
              <tr>
                <th scope="col">Account Id</th>
                <th scope="col">Station Id</th>
                <th scope="col">Send E-mail?</th>
                <th scope="col">Send Text?</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {alertSettings.map((setting) => (
                <tr key={setting.Id}>
                  <td>{setting.AccountId}</td>
                  <td>{setting.StationId}</td>
                  <td>{setting.SendEmail ? "Yes" : "No"}</td>
                  <td>{setting.SendText ? "Yes" : "No"}</td>
                  <td>
                    <Button
                      variant="light"
                      onClick={() => updateFormHandler(setting)}
                    >
                      <FontAwesomeIcon icon="edit" />
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="light"
                      onDoubleClick={() =>
                        alertSettingDeleteHandler(setting.Id)
                      }
                    >
                      <FontAwesomeIcon icon="trash-alt" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {!alertSettings && <Spinner animation="border" />}
        <Button onClick={() => showCreateForm()}>Add</Button>
      </LxCard>
    </>
  );
};

export default Alerts;
