import {
  Container,
  Dropdown,
  Nav,
  Navbar,
  Button,
  NavDropdown,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAuth from "../../contexts/useAuth";

const MainHeader = () => {
  const { user, logout } = useAuth();

  const navigate = useNavigate();

  return (
    <Navbar collapseOnSelect expand="lg" bg="light">
      <Container>
        <Navbar.Brand as={NavLink} to="/">
          LX-PRO
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={NavLink} to="/stations">
              Stations
            </Nav.Link>
            <Nav.Link as={NavLink} to="/grid">
              Grid
            </Nav.Link>
            <Nav.Link as={NavLink} to="/alarms">
              Alarms
            </Nav.Link>
            <Nav.Link as={NavLink} to="/reports">
              Reports
            </Nav.Link>
            <Nav.Link as={NavLink} to="/map">
              Map
            </Nav.Link>
          </Nav>
          <Nav>
            {user?.Role === "Admin" && (
              <NavDropdown
                title={
                  <>
                    <FontAwesomeIcon icon="gear" />
                    {" Config"}
                  </>
                }
              >
                <NavDropdown.Item as={NavLink} to="/config/users">
                  Users
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/config/stations">
                  Stations
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/config/alerts">
                  Alerts
                </NavDropdown.Item>
              </NavDropdown>
            )}
            {user && (
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <FontAwesomeIcon icon="user" />
                  {` ${user.FirstName} ${user.LastName}`}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => navigate("/account")}>
                    Settings
                  </Dropdown.Item>
                  <Dropdown.Item onClick={logout}>Sign Out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
            {!user && (
              <Button onClick={() => navigate("/login")}>
                <FontAwesomeIcon icon="user" />
                {` Sign In`}
              </Button>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MainHeader;
