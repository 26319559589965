import { DigitalInput } from "../../../models/digitalinput";

interface Props {
  digitalInput: DigitalInput;
}

const StationOnTimesRow = ({ digitalInput }: Props) => {
  return (
    <tr>
      <th scope="row">{digitalInput.Point}</th>
      <td>{digitalInput.Config.OnTimeDesc}</td>
      <td>{(digitalInput.State.OnTimeTotal / 10).toFixed(1)}</td>
      <td>{(digitalInput.State.OnTimeToday / 10).toFixed(1)}</td>
      <td>{(digitalInput.State.OnTimeYday / 10).toFixed(1)}</td>
    </tr>
  );
};

export default StationOnTimesRow;
