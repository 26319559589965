import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { StationFullConfig } from "../../models/station";
import agent from "../../api/agent";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

interface Props {
  station: StationFullConfig;
}

interface ErrorData {
  message: string;
}

const StationEditForm = ({ station }: Props) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<StationFullConfig>({
    defaultValues: station,
  });

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (stationConfig: StationFullConfig) => agent.Stations.update(stationConfig),
    {
      onSuccess: (stationConfig) => {
        queryClient.invalidateQueries([
          ["station", stationConfig.StationId],
          ["station-config", stationConfig.StationId.toString()],
        ]);
        toast.success(`Station ${stationConfig.StationId} updated!`);
      },
      onError: (error: AxiosError, variables) => {
        if (error.response) {
          let msg = (error.response.data as ErrorData).message;
          toast.error(`Error updating Station ${variables.StationId}: ${msg}`);
        } else {
          toast.error(`Error updating Station ${variables.StationId}.`);
        }
      },
    }
  );

  const onSubmit: SubmitHandler<StationFullConfig> = (data) => {
    mutate(data, { onError: () => reset() });
  };

  return (
    <Container fluid>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <Row>
            <Form.Group className="mb-3" controlId="stationId" as={Col}>
              <Form.Label>Station Id</Form.Label>
              <Controller
                name="StationId"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    type="number"
                    {...field}
                    isInvalid={!!errors.StationId}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.StationId?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="siteName" as={Col}>
              <Form.Label>Site Name</Form.Label>
              <Controller
                name="Config.SiteName"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    {...field}
                    isInvalid={!!errors.Config?.SiteName}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.Config?.SiteName?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Col>
              <Row>
                <Form.Group className="mb-3" controlId="enable" as={Col}>
                  <Form.Label>Enable</Form.Label>
                  <Controller
                    name="Config.Enable"
                    control={control}
                    render={({ field }) => (
                      <Form.Check
                        type="switch"
                        ref={field.ref}
                        onBlur={field.onBlur}
                        name={field.name}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                        isInvalid={!!errors.Config?.Enable}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.Config?.Enable?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="commFailureText"
                  as={Col}
                >
                  <Form.Label>Comm Failure Alert</Form.Label>
                  <Controller
                    name="Config.CommFailureText"
                    control={control}
                    render={({ field }) => (
                      <Form.Check
                        type="switch"
                        ref={field.ref}
                        onBlur={field.onBlur}
                        name={field.name}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                        isInvalid={!!errors.Config?.CommFailureText}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.Config?.Enable?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={8}>
              <Form.Group className="mb-3" controlId="address">
                <Form.Label>Address</Form.Label>
                <Controller
                  name="Config.Address"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      type="text"
                      {...field}
                      isInvalid={!!errors.Config?.Address}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.Config?.Address?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Form.Group className="mb-3" controlId="region" as={Col}>
              <Form.Label>Region</Form.Label>
              <Controller
                name="Config.Region"
                control={control}
                render={({ field }) => (
                  <Form.Select
                    aria-label="Select Region"
                    {...field}
                    isInvalid={!!errors.Config?.Region}
                  >
                    <option>Select Region</option>
                    <option value="North">North</option>
                    <option value="Central">Central</option>
                    <option value="South">South</option>
                  </Form.Select>
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.Config?.Region?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="ipAddress" as={Col}>
              <Form.Label>IP Address</Form.Label>
              <Controller
                name="Config.IpAddress"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    {...field}
                    isInvalid={!!errors.Config?.IpAddress}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.Config?.IpAddress?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="longitude" as={Col}>
              <Form.Label>Longitude</Form.Label>
              <Controller
                name="Config.Longitude"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    type="number"
                    {...field}
                    isInvalid={!!errors.Config?.Longitude}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.Config?.Longitude?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="latitude" as={Col}>
              <Form.Label>Latitude</Form.Label>
              <Controller
                name="Config.Latitude"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    type="number"
                    {...field}
                    isInvalid={!!errors.Config?.Latitude}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.Config?.Latitude?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Container>
        <Table responsive striped hover>
          <thead>
            <tr>
              <th>Point</th>
              <th>Enable</th>
              <th>Name</th>
              <th>On Desc.</th>
              <th>Off Desc.</th>
              <th>Count</th>
              <th>Count Desc.</th>
              <th>On Time</th>
              <th>On Time Desc.</th>
              <th>Alarm</th>
              <th>Unsol.</th>
              <th>Text</th>
              <th>State to Alarm On</th>
              <th>Alarm Delay</th>
            </tr>
          </thead>
          <tbody>
            {station.DigitalInputs.map((di, idx) => (
              <tr key={idx}>
                <td>{di.Point}</td>
                <td>
                  <Controller
                    name={`DigitalInputs.${idx}.Config.Enable`}
                    control={control}
                    render={({ field }) => (
                      <Form.Check
                        type="switch"
                        ref={field.ref}
                        onBlur={field.onBlur}
                        name={field.name}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                        isInvalid={
                          !!errors.DigitalInputs &&
                          !!errors.DigitalInputs[idx]?.Config?.Enable
                        }
                      />
                    )}
                  />
                </td>
                <td>
                  <Controller
                    name={`DigitalInputs.${idx}.Config.Name`}
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        type="text"
                        {...field}
                        isInvalid={
                          !!errors.DigitalInputs &&
                          !!errors.DigitalInputs[idx]?.Config?.Name
                        }
                      />
                    )}
                  />
                </td>
                <td>
                  <Controller
                    name={`DigitalInputs.${idx}.Config.State1Desc`}
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        type="text"
                        {...field}
                        isInvalid={
                          !!errors.DigitalInputs &&
                          !!errors.DigitalInputs[idx]?.Config?.State1Desc
                        }
                      />
                    )}
                  />
                </td>
                <td>
                  <Controller
                    name={`DigitalInputs.${idx}.Config.State0Desc`}
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        type="text"
                        {...field}
                        isInvalid={
                          !!errors.DigitalInputs &&
                          !!errors.DigitalInputs[idx]?.Config?.State0Desc
                        }
                      />
                    )}
                  />
                </td>
                <td>
                  <Controller
                    name={`DigitalInputs.${idx}.Config.Counter`}
                    control={control}
                    render={({ field }) => (
                      <Form.Check
                        type="switch"
                        ref={field.ref}
                        onBlur={field.onBlur}
                        name={field.name}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                        isInvalid={
                          !!errors.DigitalInputs &&
                          !!errors.DigitalInputs[idx]?.Config?.Counter
                        }
                      />
                    )}
                  />
                </td>
                <td>
                  <Controller
                    name={`DigitalInputs.${idx}.Config.CounterDesc`}
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        type="text"
                        {...field}
                        isInvalid={
                          !!errors.DigitalInputs &&
                          !!errors.DigitalInputs[idx]?.Config?.CounterDesc
                        }
                      />
                    )}
                  />
                </td>
                <td>
                  <Controller
                    name={`DigitalInputs.${idx}.Config.OnTime`}
                    control={control}
                    render={({ field }) => (
                      <Form.Check
                        type="switch"
                        ref={field.ref}
                        onBlur={field.onBlur}
                        name={field.name}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                        isInvalid={
                          !!errors.DigitalInputs &&
                          !!errors.DigitalInputs[idx]?.Config?.OnTime
                        }
                      />
                    )}
                  />
                </td>
                <td>
                  <Controller
                    name={`DigitalInputs.${idx}.Config.OnTimeDesc`}
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        type="text"
                        {...field}
                        isInvalid={
                          !!errors.DigitalInputs &&
                          !!errors.DigitalInputs[idx]?.Config?.OnTimeDesc
                        }
                      />
                    )}
                  />
                </td>
                <td>
                  <Controller
                    name={`DigitalInputs.${idx}.Config.Alarm`}
                    control={control}
                    render={({ field }) => (
                      <Form.Check
                        type="switch"
                        ref={field.ref}
                        onBlur={field.onBlur}
                        name={field.name}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                        isInvalid={
                          !!errors.DigitalInputs &&
                          !!errors.DigitalInputs[idx]?.Config?.Alarm
                        }
                      />
                    )}
                  />
                </td>
                <td>
                  <Controller
                    name={`DigitalInputs.${idx}.Config.Unsolicited`}
                    control={control}
                    render={({ field }) => (
                      <Form.Check
                        type="switch"
                        ref={field.ref}
                        onBlur={field.onBlur}
                        name={field.name}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                        isInvalid={
                          !!errors.DigitalInputs &&
                          !!errors.DigitalInputs[idx]?.Config?.Unsolicited
                        }
                      />
                    )}
                  />
                </td>
                <td>
                  <Controller
                    name={`DigitalInputs.${idx}.Config.Text`}
                    control={control}
                    render={({ field }) => (
                      <Form.Check
                        type="switch"
                        ref={field.ref}
                        onBlur={field.onBlur}
                        name={field.name}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                        isInvalid={
                          !!errors.DigitalInputs &&
                          !!errors.DigitalInputs[idx]?.Config?.Text
                        }
                      />
                    )}
                  />
                </td>
                <td>
                  <Controller
                    name={`DigitalInputs.${idx}.Config.StateToAlarmOn`}
                    control={control}
                    render={({ field }) => (
                      <Form.Check
                        type="switch"
                        ref={field.ref}
                        onBlur={field.onBlur}
                        name={field.name}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                        isInvalid={
                          !!errors.DigitalInputs &&
                          !!errors.DigitalInputs[idx]?.Config?.StateToAlarmOn
                        }
                      />
                    )}
                  />
                </td>
                <td>
                  <Controller
                    name={`DigitalInputs.${idx}.Config.Delay`}
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        type="number"
                        {...field}
                        isInvalid={
                          !!errors.DigitalInputs &&
                          !!errors.DigitalInputs[idx]?.Config?.Delay
                        }
                      />
                    )}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Button type="submit" disabled={isLoading}>
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default StationEditForm;
