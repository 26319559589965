import {
  DateTimePicker,
  DateTimePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { StationReportForm } from "../../models/station-list";
import { DateTime } from "luxon";
import LxCard from "../layout/LxCard";

interface Props {
  points: StationReportForm[];
  onGenerateHourlyReport: (
    start: Date,
    end: Date,
    point: StationReportForm
  ) => void;
}

const HourlyReportForm = ({ points, onGenerateHourlyReport }: Props) => {
  const yesterday = DateTime.now().plus({ days: -1 });

  const [start, setStart] = useState<Date | undefined>(
    new Date(yesterday.toISO() ?? "")
  );
  const [end, setEnd] = useState<Date | undefined>(new Date());
  const [point, setPoint] = useState<StationReportForm>(points[0]);

  const disabled = !start && !end && !point;

  const generateReportHandler = () => {
    if (start && end) {
      onGenerateHourlyReport(start, end, point);
    }
  };

  const startChangeHandler = (event: DateTimePickerChangeEvent) => {
    if (event.value) {
      setStart(event.value);
    }
  };

  const endChangeHandler = (event: DateTimePickerChangeEvent) => {
    if (event.value) {
      setEnd(event.value);
    }
  };

  const pointChangeHandler = (event: DropDownListChangeEvent) => {
    setPoint(event.value);
  };

  return (
    <LxCard
      title="Select Date and Point"
      footer={
        <Button onClick={generateReportHandler} disabled={disabled}>
          Generate
        </Button>
      }
    >
      <Row className="mb-3">
        <Col lg={4}>
          <label htmlFor="startpicker" className="form-label mt-4">
            Start Date
          </label>
          <DateTimePicker
            onChange={startChangeHandler}
            value={start}
            max={end}
            id="startpicker"
            width="100%"
          />
        </Col>
        <Col lg={4}>
          <label htmlFor="endpicker" className="form-label mt-4">
            End Date
          </label>
          <DateTimePicker
            onChange={endChangeHandler}
            value={end}
            min={start}
            id="endpicker"
            width="100%"
          />
        </Col>
        <Col lg={4}>
          <label htmlFor="pointSelectHourly" className="form-label mt-4">
            Point
          </label>
          <DropDownList
            data={points}
            onChange={pointChangeHandler}
            value={point}
            id="pointSelectHourly"
            style={{ width: "100%" }}
            textField="Name"
            dataItemKey="Id"
          />
        </Col>
      </Row>
    </LxCard>
  );
};

export default HourlyReportForm;
