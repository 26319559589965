import { DigitalInput } from "../../../models/digitalinput";

interface Props {
  digitalInput: DigitalInput;
}

const StationOffTimesRow = ({ digitalInput }: Props) => {
  return (
    <tr>
      <th scope="row">{digitalInput.Point}</th>
      <td>{digitalInput.Config.OffTimeDesc}</td>
      <td>{(digitalInput.State.OffTimeTotal / 10).toFixed(1)}</td>
      <td>{(digitalInput.State.OffTimeToday / 10).toFixed(1)}</td>
      <td>{(digitalInput.State.OffTimeYday / 10).toFixed(1)}</td>
    </tr>
  );
};

export default StationOffTimesRow;
